.headline-1 {
  margin-top: 0;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 32px;
    line-height: 48px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.headline-2 {
  margin-top: 0;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 28px;
    line-height: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &.mb-8 {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.headline-3 {
  margin-top: 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.headline-4 {
  margin-top: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 22px;
    line-height: 28px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.headline-5 {
  margin-top: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 28px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.headline-6 {
  margin-top: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 26px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

*[class^="headline-"].blue {
  color: var(--secondary);
}

.subtitle-1 {
  margin-top: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 26px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.subtitle-2 {
  margin-top: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 26px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.subtitle-3 {
  margin-top: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.lead-1 {
  margin-top: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 26px;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.lead-2 {
  margin-top: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.body-1 {
  margin-top: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.body-2 {
  margin-top: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.label-1 {
  margin-top: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.label-2 {
  margin-top: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.label-3 {
  margin-top: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}