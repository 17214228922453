@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import "fonts/icoMoon/style.css";
@import "styles/_typography.scss";

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}